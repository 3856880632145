import React, { useState } from "react"
import { graphql } from "gatsby"
import { Container, SEO, BlogList } from "../components"

const Blog = (props) => {
  const [search, setSearch] = useState("")
  const [posts] = useState(props.data.posts.edges)
  const [filtered, setFiltered] = useState(props.data.posts.edges)

  const filterPosts = (value) => {
    const newFiltered = posts.filter((post) =>
      post.node.frontmatter.title.toLowerCase().includes(value.toLowerCase())
    )
    setFiltered(newFiltered)
  }

  const handleChange = (event) => {
    const { value } = event.target
    setSearch(value)
    filterPosts(value)
  }

  return (
    <Container title="Blog">
      <SEO />
      <div className="blogSearch">
        <input
          type="text"
          placeholder="Search"
          name="search"
          value={search}
          onChange={handleChange}
        />
      </div>
      <h1>
        Blog - <span className="special2">{filtered.length}</span>
      </h1>
      <BlogList title="" posts={filtered} />
    </Container>
  )
}

export default Blog

export const pageQuery = graphql`
  query BlogQuery {
    posts: allMarkdownRemark(
      limit: 1000
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 140)
          id
          frontmatter {
            title
            date
            path
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 120, quality: 100) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
              }
            }
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`
